<template>
  <v-card style="min-height: 100%">
    <v-card-text>
      <v-row style="margin-bottom: 50px">
        <v-col cols="12" sm="4">
          <v-img v-if="!getItem().image" class="mx-5 my-3" max-height="300" src="@/assets/pittogramma-positivo-MyFamily.jpg" contain></v-img>
          <v-img v-else class="mx-5 my-3" max-height="300" :src="getItem().image" contain></v-img>
          <!-- da impostare quando avremo più versioni delle immagini > lazy-src="path a versione 'leggera'"  -->
        </v-col>
        <v-col cols="12" sm="8">
          <v-form v-model="validItem">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                      :label="$t('family')"
                      item-text="description"
                      item-value="id"
                      v-model="getItem().familyId"
                      :items="families"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="getItem().code"
                    :label="$t('code')"
                    :rules="codeRules"
                    required
                  ></v-text-field>
                </v-col>
                <!-- INIZIO - DA VALORIZZARE -->
                <v-col cols="12" sm="3" md="3">
                  <v-select
                      :label="$t('statCategory')"
                      item-text="description"
                      item-value="id"
                      v-model="getItem().itemStatisticalCollectionId"
                      :items="[]"
                  ></v-select>
                </v-col>
                <!-- FINE - DA VALORIZZARE -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                      v-model="getItem().description"
                      :label="$t('description')"
                      :rules="descriptionRules"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                    v-model="getItem().active"
                    :label="$t('active')"
                    color="primary"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="getItem().obsolete"
                    :label="$t('obsolete')"
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>

        <v-row>
            <v-col cols="12" sm="4">
                <v-card class="mx-auto">
                    <v-card-title class="white">
                        <v-text-field
                            v-model="customFilter.leftFilter"
                            :label="$t('filter')"
                        ></v-text-field>
                    </v-card-title>
                    <v-virtual-scroll :items="getLeftItems" :item-height="50" height="400">
                        <template v-slot:default="{ item }">
                            <v-list-item>
                            <v-list-item-avatar>
                                <v-avatar size="56" tile>
                                    <v-img src="@/assets/pittogramma-positivo-MyFamily.jpg" contain></v-img>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.code + " - " + item.description }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn depressed small @click="addItemToKit(item)">
                                    <v-icon color="orange darken-4" left>mdi-plus</v-icon>
                                    {{ $t('addParam', {param:$t('item')}) }}
                                </v-btn>
                            </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card class="mx-auto">
                    <v-virtual-scroll :items="getRightItems" :item-height="120" height="500">
                        <template v-slot:default="{ item }">
                            <v-card color="white">
                              <v-card-title>
                                <span class="title font-weight-light">{{ getItemDescription(item.itemId) }}</span>
                              </v-card-title>
                              <v-card-text class="headline font-weight-bold">
                                <v-row>
                                  <v-col cols="12" sm="3">
                                    <v-text-field v-model="item.quantity" :label="$t('quantity')" type="number" outlined dense min="0"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="2">
                                    <v-text-field v-model="item.discount" :label="$t('discount')" type="number" step=".01" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="2">
                                    <v-checkbox v-model="item.isGift" dense outlined :label="$t('gift')"></v-checkbox>
                                  </v-col>
                                  <v-col cols="12" sm="2">
                                    <v-checkbox v-model="item.idLoanForUse" dense outlined :label="$t('loan')"></v-checkbox>
                                  </v-col>
                                  <v-col cols="12" sm="1" offset-sm="2">
                                    <v-btn block color="error" @click="removeItemFromKit(item)">
                                      <v-icon color="white">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                        </template>
                        <template v-slot:no-data>
                          <p v-t="'noData'"></p>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
        </v-row>
    </v-card-text>

    <v-card-text style="display: flex; justify-content: flex-end;">
        <v-fab-transition>
          <v-btn color="primary" class="align-right" dark fab large top @click="save">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-fab-transition>
    </v-card-text>

  </v-card>
</template>

<script>
import { sync, get, call } from "vuex-pathify";

export default {
  created() { 
    if (this.kitId != 'new') {
      this.apiItemKitIdGet(this.kitId).then((res) => {
        this.currentKit = res || this.currentKit;
        this.apiItemKitIdItemKitRowsGet(this.kitId)
        .then((collection) => {
          this.kitRows = collection;
        }); 
      });
    } else {
      this.kitRows.length = 0;
    }
    this.initItemCollection();
    this.initFamilyCollection();
    this.initGroupCollection();
    this.initCollectionCollection();
  },
  props: [ "kitId" ],
  computed: {
    // RULES
    codeRules(){
      return [
          (v) => !!v || this.$t('required', {param: this.$t('code')}),
          (v) => (v || "").indexOf(" ") < 0 || this.$t('whitespaces'),
          (v) => v.length <= 20 || this.$t('lessThan', {param:this.$t('code'), qty: v.length}),
      ]
    },
    descriptionRules(){
      return [
          (v) => !!v || this.$t('required', {param: this.$t('description')}),
          (v) => v.length <= 128 || this.$t('lessThan', {param:this.$t('description'), qty: v.length}),
      ]
    },
    items: sync("items/items"),
    families: sync("families/families"),
    groups: sync("groups/groups"),
    collections: sync("collections/collections"),
    singleItemPricesList: get("items/singleItemPricesList"),
    itemKits: sync("itemKits/itemKits"),
    kitRows: sync('itemKits/itemKitRows'),
    getLeftItems() {
      return this.items
        .filter(item => !this.getItem().familyId || item.itemFamilyId == this.getItem().familyId)
        .filter(el => !this.customFilter.leftFilter 
          || el.code?.toUpperCase().includes(this.customFilter?.leftFilter?.toUpperCase())
          || el.description?.toUpperCase().includes(this.customFilter?.leftFilter?.toUpperCase()));
    },
    getRightItems: function() {
      return this.kitRows;
    },
  },
  data: () => ({
    validItem: false,
    currentKit : { code : "", description : "" },
    customFilter : {
      leftFilter : ""
    },
    filter: {
      logic: "and", 
      filters: []
    },
    tempImage : {},
  }),
  methods: {
    getItem: function() {
      // if (!this.currentKit.id) this.currentKit = this.itemKits.find(el => el.id == this.kitId) || this.currentKit;
      return this.currentKit
    },
    getPic: function() {
      return this.currentKit.image || '@/assets/pittogramma-positivo-MyFamily.jpg';
    },
    readInput: function() {
      if (!this.tempImage.content) {
        return;
      }
      var reader = new FileReader();
      reader.readAsText(this.tempImage.content);
      reader.onload = () => {
        this.$nextTick(() => {
          this.currentKit.image = reader.result;
        })
      }
    },

    getItemDescription: function(id) {
      const item = (this.items.find(el => el.id == id) || {});
      return item.code + " - " + item.description;
    },

    async save() {
      if (!this.currentKit.id) {
        this.apiItemKitPost(this.currentKit).then((response) => {
          this.apiItemKitIdItemKitRowsPatch({
            id: +response.id,
            kitRows: this.kitRows
          })
        })
      } else {
        this.apiItemKitIdPut(this.currentKit).then((response) => {
          this.apiItemKitIdItemKitRowsPatch({
            id: +this.currentKit.id,
            kitRows: this.kitRows
          })
        })
      }
    },

    ...call("items/*"),
    ...call("itemKits/*"),
    ...call("families/*"),
    ...call("groups/*"),
    ...call("collections/*"),
    ...call("itemPrices/*"),
    ...call("priceCatalogs/*"),

    addItemToKit(item) {
      this.kitRows.push({ 
        itemId : item.id, 
        quantity : 1,
        deleted : false,
        isGift : false,
        isLoanForUse : false,
        discount : 0
      });
    },
    removeItemFromKit(item) {
      let index = this.kitRows.findIndex(el => el.itemId == item.itemId);
      if (index >= 0) this.kitRows.splice(index, 1);
    },

  },
};
</script>